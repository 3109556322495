import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Prefooter from '../components/prefooter'
import Video from '../images/failure.mp4'

export const FailurePageTemplate =({
    title
}) => (
    <div>
    <video className="fail-video-bg" autoplay="autoplay" id="fail-video">
      <source src={Video} type="video/mp4" />
    </video>
    <Prefooter />
    </div>
)

FailurePageTemplate.propTypes = {
    title: PropTypes.string,
}

const FailurePage = ({ data }) => {
    const { frontmatter } = data.markdownRemark
  
    return (
      <Layout language="English">
        <Helmet title={frontmatter.title}  bodyAttributes={{
        class: 'failure-page'
    }} />
        <FailurePageTemplate
          title={frontmatter.title}
        />
      </Layout>
    )
}

export default FailurePage

export const pageQuery = graphql`
query FailurePage($id: String!) {
  markdownRemark(id: { eq: $id }) {
    html
    frontmatter {
      title
    }
  }
}
`